import React, { Component } from 'react'
import axios from 'axios';
import {config} from '../../config/config';

class PartialCheckInForm extends Component {

	constructor (props) {
		super(props);
		this.state = {
			fields: {
				actual_no_of_pax: {value: 0, error: ''}
			}
		}
	}

	componentDidMount () {
		let {inviteId, noOfPax} = this.props;
		let {fields} = this.state;
		this.getInviteByInviteId(inviteId);
		if (noOfPax) {
			fields.actual_no_of_pax.value = noOfPax;
			this.setState({fields: fields});
		}
	}

	render () {
		return (
			<form onSubmit={this.handleSubmit.bind(this)}>
				<label>Number of pax checking in:</label><br/>
				<input
					name="actual_no_of_pax"
					value={this.state.fields.actual_no_of_pax.value}
					style={{fontSize: 20, textAlign: 'center', width: 80}}
					autoFocus={true}
					onFocus={(e) => {e.target.select();}}
					onChange={(e) => {
						let {fields} = this.state;
						let value = e.target.value;
						fields.actual_no_of_pax.value = value;
						this.setState({fields: fields});
					}}
				/>
				{this.props.inviteId && <div>
				<input type="submit" onClick={this.handleSubmit.bind(this)} value="Check In"/></div>}
			</form>
		);
	}

	handleSubmit (e) {
		e.preventDefault();
		let {inviteId} = this.props;
		let {fields} = this.state;
		if (inviteId && fields.actual_no_of_pax.value && parseInt(fields.actual_no_of_pax.value) > 0) {
			this.checkInInvite(inviteId, fields.actual_no_of_pax.value);
		}
	}

	getInviteByInviteId (inviteId) {
		axios.get(`${config.apiUrl}/inviteId?id=${inviteId}`)
			.then(function (response) {
				// handle success
				console.log(response);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}

	checkInInvite (inviteId, actualNoOfPax) {
		const self = this;
		axios.put(`${config.apiUrl}/api/eventInvite/checkInInvite`, {
			inviteId: inviteId, actualNoOfPax: actualNoOfPax
		})
			.then(function (response) {
				// handle success
				// console.log(response);
				let responseData = response && response.data && response.data ? response.data : null;
				if (responseData && responseData.status === 'ok') {
					// console.log('response.data.data.invite =', responseData.data.invite);
					let inviteData = responseData.data && responseData.data.invite ? responseData.data.invite : null;
					if (inviteData) {
						let tableNo = inviteData.event_table_no;
						let tableNoOfPaxAssigned = inviteData.event_table_no_of_pax_assigned;
						alert(`
							Table No: ${tableNo}
							No of Pax Assigned: ${tableNoOfPaxAssigned}
						`);
						if (self.props.callback) self.props.callback();
					}
				}
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}

}

export default PartialCheckInForm;
