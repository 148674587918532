import React from 'react';
import logo from './logo.svg';
import './App.css';
import CheckInIndex from "./Components/CheckInIndex";

function App () {
	return (
		<div className="App">
			<header className="App-header">
				<div style={{marginBottom: 30}}>
					<img style={{width: 150}} src="https://www.e-attendee.com/assets/images/eattendee-full-logo-light.png"/>
				</div>
				<CheckInIndex/>
			</header>
		</div>
	);
}

export default App;
