import * as generalHelper from './general.js';

String.prototype.replaceAll = function(searchStr, replaceStr) {
	var str = this;

	// escape regexp special characters in search string
	searchStr = searchStr.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

	return str.replace(new RegExp(searchStr, 'gi'), replaceStr);
};

/**
 * check variable for empty value
 * @param value
 * @returns {boolean}
 */
export function isEmpty (value) {
	// test results
	//---------------
	// []        true, empty array
	// {}        true, empty object
	// null      true
	// undefined true
	// ""        true, empty string
	// ''        true, empty string
	// 0         false, number
	// true      false, boolean
	// false     false, boolean
	// Date      false
	// function  false

	if (value === undefined)
		return true;

	if (typeof (value) === 'function' ||
		typeof (value) === 'number' ||
		typeof (value) === 'boolean' ||
		Object.prototype.toString.call(value) === '[object Date]')
		return false;

	if (value === null || value.length === 0)        // null or 0 length array
		return true;

	if (typeof (value) === "object") {
		// empty object

		var r = true;

		if (Object.keys(value).length > 0) {
			r = false;
		}

		return r;
	}

	if (typeof (value) === "string" && value.trim() === "") {
		return true;
	}

	return false;
}

/**
 * inverse of isEmpty function
 * @param value
 * @returns {boolean}
 */
export function notEmpty (value) {
	return !isEmpty(value);
}

/**
 * get current environment based on hostname
 * @returns {*}
 */
export function getEnv () {
	var debug = false,
		hostName = window.location.hostname,
		processEnvironment = function (debug, environment) {
			if (debug) console.log('Current environment: ' + environment);
			return environment;
		};
	// development environment
	if (hostName.indexOf('localhost') > -1) {
		return processEnvironment(debug, 'development');
	}
	// production environment
	else {
		return processEnvironment(debug, 'production');
	}
}

/**
 * capitalize all first letters of sentence
 * @param str
 * @returns {string}
 */
export function ucFirstAllWords (str) {
	var pieces = str.split(" ");
	for (var i = 0; i < pieces.length; i++) {
		var j = pieces[i].charAt(0).toUpperCase();
		pieces[i] = j + pieces[i].substr(1);
	}
	return pieces.join(" ");
}

/**
 * prettify constant value for display
 * @param value
 * @returns {string}
 */
export function prettifyConstant (value) {
	if (generalHelper.isEmpty(value)) {
		return  '';
	} else {
		return generalHelper.ucFirstAllWords(value.replaceAll('_', ' '));
	}
}
