import React from 'react';
import axios from "axios";
import {config} from "../../config/config";
import * as authHelper from "../../helpers/auth";
import * as generalHelper from '../../helpers/general';
import PartialCheckInForm from "./PartialCheckInForm";

export class PartialInviteForm extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			invites: [],
			selectedInviteId: '',
			selectedInviteNoOfPax: 0,
			fields: {
				search: {value: '', error: '', dirty: false}
			}
		}
	}

	render () {
		const self = this;
		let { fields, invites, selectedInviteId, selectedInviteNoOfPax } = this.state;
		return (
			<div>
				{!selectedInviteId &&
				<form style={{paddingTop: 10, paddingBottom: 10}} onSubmit={(e) => this.handleSubmit(e)}>
					<div>
						<input
							type="text" name="search" value={fields.search.value}
							placeholder="Search invite E.g. name or contact number"
							style={{width: 300}}
							onChange={(e) => this.onChange(e)}
							autoFocus={true}
						/>
					</div>
					<div>
						<input type="submit" value="Search" onClick={(e) => this.handleSubmit(e)}/>
					</div>
				</form>}

				{!selectedInviteId && (invites && invites.length > 0 ?
				<div>
					<p><b>{invites.length} invitations found</b></p>
					<table>
						<tbody>
						{invites.map((invite) => {
							let inviteSingleData = [];
							for (let key in invite) {
								switch (key) {
									case 'attendee_first_name':
									case 'attendee_last_name':
									case 'attendee_contact':
									case 'attendee_email':
									case 'attendee_address':
									case 'attendee_postal_code':
									case 'categories':
									case 'additional_remarks':
									case 'no_of_pax':
										inviteSingleData.push({key: key, value: invite[key]});
										break;
									default:
										continue;
								}
							}
							inviteSingleData.push({key: '', value: <button onClick={() => {
								self.setState({selectedInviteId: invite.id, selectedInviteNoOfPax: invite.no_of_pax});
							}}>Check-in this invitation</button>})
							return (<tr>
								<td>
									<table cellPadding={5} style={{border: '1px solid #fff', width: '100%', fontSize: 10, marginBottom: 10}}>
										<tbody>
										{inviteSingleData.map((inviteSingle) => {
											return <tr>
												<td style={{border: '1px solid #808080', textAlign: 'right', width: 120, fontWeight: 'bold'}}>{generalHelper.prettifyConstant(inviteSingle.key)}</td>
												<td style={{border: '1px solid #808080', textAlign: 'left'}}>{inviteSingle.value}</td>
											</tr>
										})}
										</tbody>
									</table>
								</td>
							</tr>);
						})}
						</tbody>
					</table>
				</div> : <div>No invitations found yet</div>)}

				{selectedInviteId &&
				<PartialCheckInForm
					inviteId={selectedInviteId}
					noOfPax={selectedInviteNoOfPax}
					callback={() => {
						self.setState({selectedInviteId: '', selectedInviteNoOfPax: 0, invites: []});
					}}
				/>}

			</div>
		);
	}

	onChange (e) {
		let { fields } = this.state;
		if (fields && e && e.target) {
			var key = e.target.name;
			var value = e.target.value;
			fields[key].value = value;
			fields[key].error = '';
			fields[key].dirty = true;
		}
		this.setState({fields: fields});
	}

	handleSubmit (e) {
		e.preventDefault();
		const self = this;
		let { fields, invites } = this.state;
		if (fields.search.value) {
			axios.post(`${config.apiUrl}/api/invite/search`, {
				token: authHelper.getAccessToken(),
				search: fields.search.value
			})
				.then(function (response) {
					// handle success
					console.log(response);
					let responseData = response || null;
					if (responseData && responseData.status === 200) {
						// console.log('response.data.data.invite =', responseData.data.invite);
						invites = responseData.data && responseData.data.invites ? responseData.data.invites : null;
						console.log('invites =', invites);
						self.setState({invites: invites});
					}
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				})
				.finally(function () {
					// always executed
				});
		}
	}
}

export default PartialInviteForm;

