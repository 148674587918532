import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import PartialCheckInForm from "./PartialCheckInForm";

class PartialQrReader extends Component {
	constructor(props){
		super(props)
		// this.state = {
		// 	delay: 100,
		// 	result: 'No result',
		// }
		this.state = { cameraId: undefined, delay: 200, devices: [], loading: false, result: 'No result' };

		this.handleScan = this.handleScan.bind(this)
	}

	componentDidMount() {
		const { selectFacingMode } = this.props

		this.loadVideoDevices();
	}

	loadVideoDevices () {
		if (navigator) {
			this.setState({
				loading: true,
			})
			let selectedVideo = '';
			function gotDevices(deviceInfos) {
				let videoSelect = []
				// Handles being called several times to update labels. Preserve values.
				// const values = selectors.map(select => select.value);
				// selectors.forEach(select => {
				// 	while (select.firstChild) {
				// 		select.removeChild(select.firstChild);
				// 	}
				// });
				for (let i = 0; i !== deviceInfos.length; ++i) {
					const deviceInfo = deviceInfos[i];
					const option = document.createElement('option');
					option.value = deviceInfo.deviceId;
					// if (deviceInfo.kind === 'audioinput') {
					// 	option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
					// 	audioInputSelect.appendChild(option);
					// } else if (deviceInfo.kind === 'audiooutput') {
					// 	option.text = deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
					// 	audioOutputSelect.appendChild(option);
					// } else
					if (deviceInfo.kind === 'videoinput') {
						option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`;
						// videoSelect.appendChild(option);
						videoSelect.push(option)
					} else {
						console.log('Some other kind of source/device: ', deviceInfo);
					}
				}
				// selectors.forEach((select, selectorIndex) => {
				// 	if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
				// 		select.value = values[selectorIndex];
				// 	}
				// });
			}

			// navigator.mediaDevices.enumerateDevices().then(gotDevices).catch((err) => {console.log(err)});

			navigator.mediaDevices.enumerateDevices()
				.then((devices) => {
					// const videoSelect = []
					// devices.forEach((device) => {
					// 	if (device.kind === 'videoinput') {
					// 		videoSelect.push(device)
					// 	}
					// })
					// return videoSelect
					let videoSelect = []
					// Handles being called several times to update labels. Preserve values.
					// const values = selectors.map(select => select.value);
					// selectors.forEach(select => {
					// 	while (select.firstChild) {
					// 		select.removeChild(select.firstChild);
					// 	}
					// });
					for (let i = 0; i !== devices.length; ++i) {
						const deviceInfo = devices[i];
						const option = {};
						option.value = deviceInfo.deviceId;
						// if (deviceInfo.kind === 'audioinput') {
						// 	option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
						// 	audioInputSelect.appendChild(option);
						// } else if (deviceInfo.kind === 'audiooutput') {
						// 	option.text = deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
						// 	audioOutputSelect.appendChild(option);
						// } else
						if (deviceInfo.kind === 'videoinput') {
							option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`;
							// videoSelect.appendChild(option);
							videoSelect.push(option)
						} else {
							console.log('Some other kind of source/device: ', deviceInfo);
						}
					}
					return videoSelect;
				})
				.then((devices) => {
					this.setState({
						cameraId: devices[0].value,
						devices,
						loading: false,
					})
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	selectCamera = () => {
		return this.state.cameraId
	}

	handleScan(data){
		// if (data) {
		// 	window.location.href = `https://www.e-attendee.com/#/organiser/event/73f8a3e8-63ab-11e7-8df0-a8e78c088273/check-in/${data}`;
		// }
		if (data) {
			this.setState({
				result: data,
			});
		}
	}
	handleError(err){
		console.error(err)
	}
	render(){
		const self = this;
		const { selectFacingMode, selectDelay, legacyMode } = this.props
		const { cameraId, devices } = this.state
		const previewStyle = {
			height: 240,
			width: 320,
		}

		return(
			<div>
				{/*<button onClick={() => {*/}
				{/*	this.loadVideoDevices();*/}
				{/*}}>Start Camera</button>*/}
				{/*{devices.length && (*/}
				{/*	<select*/}
				{/*		onChange={e => {*/}
				{/*			const value = e.target.value*/}
				{/*			alert(value);*/}
				{/*			self.setState({ cameraId: undefined }, () => {*/}
				{/*				self.setState({ cameraId: value })*/}
				{/*			})*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		{devices.map((deviceInfo, index) => (*/}
				{/*			<option value={deviceInfo.value}>{deviceInfo.text}</option>*/}
				{/*		))}*/}
				{/*	</select>*/}
				{/*)}*/}

				{(this.state.result && this.state.result.toLowerCase() !== ('No result').toLowerCase()) && <PartialCheckInForm inviteId={this.state.result}/>}

				<p>{this.state.result || 'No results yet'}</p>
				<br/>

				<QrReader
					ref="reader"
					delay={this.state.delay}
					style={previewStyle}
					onError={this.handleError}
					onScan={this.handleScan}
				/>
			</div>
		)
	}
}

export default PartialQrReader;
