import React from 'react';
import axios from "axios";
import {config} from "../../config/config";
import * as authHelper from '../../helpers/auth';

export class PartialLoginForm extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			fields: {
				password: {value: '', error: '', dirty: false}
			}
		};
	}

	render () {
		let { fields } = this.state;
		return (
			<form onSubmit={(e) => this.handleSubmit(e)}>
				<div>
					<input placeholder="Enter password" name="password" type="password" value={fields.password.value} onChange={(e) => this.onChange(e)}/>
				</div>
				<div>
					<input type="submit" value="Login" onClick={(e) => this.handleSubmit(e)} />
				</div>
			</form>
		);
	}

	onChange (e) {
		let { fields } = this.state;
		if (fields && e && e.target) {
			var key = e.target.name;
			var value = e.target.value;
			fields[key].value = value;
			fields[key].error = '';
			fields[key].dirty = true;
		}
		this.setState({fields: fields});
	}

	handleSubmit (e) {
		e.preventDefault();
		let { fields } = this.state;
		if (fields.password.value) {
			axios.post(`${config.apiUrl}/api/assistant-users/login`, {
				password: fields.password.value
			})
				.then(function (response) {
					// handle success
					// console.log(response);
					let responseData = response && response.data && response.data ? response.data : null;
					if (responseData && responseData.status === 'ok') {
						// console.log('response.data.data.invite =', responseData.data.invite);
						let accessToken = responseData.data && responseData.data.access_token ? responseData.data.access_token : null;
						authHelper.setAccessToken(accessToken);
						window.location.reload();
					}
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				})
				.finally(function () {
					// always executed
				});
		}
	}
}

export default PartialLoginForm;
