import React from 'react';
import * as authHelper from "../helpers/auth";
import PartialQrReader from "./Partials/PartialQrReader";
import PartialLoginForm from "./Partials/PartialLoginForm";
import PartialInviteForm from "./Partials/PartialInviteForm";

const VIEW_MODE = {
	QR_CODE: "qr_code",
	SEARCH: "search"
};

export class CheckInIndex extends React.Component {

	constructor (props) {
		super(props);
		this.state = {
			viewMode: VIEW_MODE.QR_CODE
		};
	}

	render () {
		return (
			<div>
				{authHelper.isAuthenticated() ? <div>
					<div style={{border: '1px solid #fff', width: 200, marginLeft: 'auto', marginRight: 'auto', paddingBottom: 10}}>
						<div><b style={{fontSize: 15}}>Switch check-in modes</b></div>
						<button onClick={() => {
							this.setState({viewMode: VIEW_MODE.SEARCH})
						}} disabled={this.state.viewMode === VIEW_MODE.SEARCH}>Search Invite</button>
						<button onClick={() => {
							this.setState({viewMode: VIEW_MODE.QR_CODE})
						}} disabled={this.state.viewMode === VIEW_MODE.QR_CODE}>QR Code</button>
					</div>
					{this.state.viewMode === VIEW_MODE.SEARCH && <PartialInviteForm/>}
					{this.state.viewMode === VIEW_MODE.QR_CODE && <PartialQrReader/>}
				</div> : <div>
					<PartialLoginForm/>
				</div>}
			</div>
		);
	}
}

export default CheckInIndex;
