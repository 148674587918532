import { getEnv } from '../helpers/general';

export const config = {

	development: {
		env: "development",
		webappUrl: "http://localhost:6011",
		apiUrl: "https://localhost:3011"
	},

	production: {
		env: "production",
		webappUrl: "https://app.e-attendee.com",
		apiUrl: "https://api.e-attendee.com"
	}

}[getEnv()];
