import { getEnv } from '../helpers/general.js';
import * as authHelper from '../helpers/auth.js';

export const cookiePrefix = 'JKH23KJH23K_' + getEnv() + '_EAWebApp_';
export const cookieAccessToken = 'AlphaTango';
// export const cookieRememberMe = 'RomeoMight';
// export const cookieEmail = 'Echo';
// export const cookieTourExpenses = 'TangoEcho';
// export const cookieCurrentEntityId = 'CharlieEchoIndia';
// export const cookieCurrentServiceProviderId = 'CharlieSierraPapaIndia';
// export const cookieIncorporationFormId = 'IndiaFoxtrotIndia';

/**
 * checks if user is authenticated by checking for access token in cookie
 * @returns {boolean}
 */
export function isAuthenticated () {
	var accessToken = authHelper.getAccessToken();
	if (accessToken && accessToken !== '') {
		return true;
	} else {
		return false;
	}
}

/**
 * sign out function
 * @param callback
 */
export function signOut (callback) {
// 	var data = { device_type: 'web' };
// 	UserModel.signout(data, callback);
}

/**
 * get all cookies and set into an array
 * @returns {{}}
 */
export function getCookies () {
	var pairs = document.cookie.split(";");
	var cookies = {};
	for (var i=0; i<pairs.length; i++){
		var pair = pairs[i].split("=");
		cookies[pair[0].trim()] = unescape(pair[1]);
	}
	return cookies;
}

/**
 * store a cookie using key and value
 * @param key
 * @param value
 */
export function setCookie (key, value) {
	authHelper.deleteCookie(key);
	document.cookie = authHelper.cookiePrefix + key + '='+ value +';';
}

/**
 * get cookie value by key
 * @param key
 * @returns {string}
 */
export function getCookie (key) {
	var cookies = authHelper.getCookies(),
		cookieKey = authHelper.cookiePrefix + key,
		accessToken = cookies[cookieKey] ? cookies[cookieKey] : '';
	return accessToken;
}

/**
 * delete a cookie by key
 * @param key
 */
export function deleteCookie (key) {
	document.cookie = authHelper.cookiePrefix + key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
 * store access token
 * @param accessToken
 */
export function setAccessToken (accessToken) {
	authHelper.deleteAccessToken();
	authHelper.setCookie(authHelper.cookieAccessToken, accessToken);
}

/**
 * delete access token
 */
export function deleteAccessToken () {
	authHelper.deleteCookie(authHelper.cookieAccessToken);
}

/**
 * get access token
 * @returns {string}
 */
export function getAccessToken () {
	return authHelper.getCookie(authHelper.cookieAccessToken);
}

/**
 * redirect user with non-authenticated session to login page
 * @param {string} [redirectDomain] Domain to overwrite default redirect behaviour
 * @param {string} [customMessage] Custom message to show user when redirected to login page
 * @param {boolean} [redirectOnLogin=true] Flag to indicate whether to redirect to previous URL after successful login
 */
// export function redirectToLogin (redirectDomain, customMessage, redirectOnLogin) {
// 	redirectOnLogin = compareBoolean(redirectOnLogin, false) ? false : true;
// 	let noRedirectFlag = findGetParameter('no_redirect');
// 	if (noRedirectFlag && noRedirectFlag == '1') return;
// 	let redirectRoute = findGetParameter('redirectRoute');
// 	let currentPathnameEncoded = encodeURIComponent(window.location.pathname);
// 	let currentSearchEncoded = encodeURIComponent(window.location.search);
// 	let redirectTo = `${redirectDomain || ''}/login?`;
// 	if (redirectOnLogin && !redirectRoute) redirectTo = `${redirectTo}&redirect_route=${currentPathnameEncoded}&redirect_search=${currentSearchEncoded}&`;
// 	if (customMessage) redirectTo = `${redirectTo}error_message=${encodeURIComponent(customMessage)}&`;
// 	redirectTo = `${redirectTo}no_redirect=${encodeURIComponent(redirectOnLogin ? 0 : 1)}&`;
// 	window.location.href = redirectTo;
// }
